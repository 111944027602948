import React, { Component } from "react";
import QVFourCoverPageData from "./QVFourCoverPageData";
import QVFourCoverPageInfo from "./QVFourCoverPageInfo";
import { propertyUtil } from "views/Pma/PropertyUtil";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { pmaActions } from "_store/_actions";
import { renderLogicUtil } from "views/Pma/RenderLogicUtil";
import KJTeamLogo from "_img/agentLogos/Kimm-Jen_LOGO_WHITE.png";

//new pma separation branch
class QVFourCoverPage extends Component {
  //inside area
  divideAreas = (areas) => {
    const { currentArea, agentData } = this.props;
    const { listgenUserID } = agentData;
    const { mlsAreaID, coverPageTitle } = currentArea;

    const { splitAreas } = propertyUtil;
    const { subdivisionName, coverPageHeader } = this.props.hoData;
    let areaSplit = splitAreas(
      subdivisionName
        ? subdivisionName
        : coverPageTitle
        ? coverPageTitle
        : coverPageHeader
        ? coverPageHeader
        : areas
    );
    let len = areaSplit.length;
    let maxCount;
    if (len === 1) {
      maxCount = areaSplit[0].length;
    } else if (len === 2) {
      maxCount = Math.max(areaSplit[0].length, areaSplit[1].length);
    } else if (len === 3) {
      maxCount = Math.max(
        areaSplit[0].length,
        areaSplit[1].length,
        areaSplit[2].length
      );
    }
    let coverPageFontSize = "";
    if (mlsAreaID === "SMA-263A") {
      coverPageFontSize = "bolded-cover-photo-text cover-page-area-text-47";
    } else if (listgenUserID === "104504") {
      coverPageFontSize = "higher-cover-page-text";
    } else if (listgenUserID === "106531" || listgenUserID === "106028") {
      coverPageFontSize = "bolded-cover-photo-text";
    } else if (mlsAreaID === "SMA-467") {
      coverPageFontSize = "cover-page-text-40";
    } else if (mlsAreaID === "SMA-395") {
      coverPageFontSize = "cover-page-text-font-39";
    } else if (mlsAreaID === "SCL-15A" || mlsAreaID === "SCL-10K") {
      coverPageFontSize = "medium-cover-page-area-text";
    } else if (
      mlsAreaID === "SBA-20FInv" ||
      mlsAreaID === "48E" ||
      mlsAreaID === "SMA-353A"
    ) {
      coverPageFontSize = "xxs-cover-page-area-text";
    } else if (
      areaSplit[0] === "Winchester Canyon" ||
      (areas = "Country Club Gate/Forest Grove")
    ) {
      coverPageFontSize = "xs-cover-page-area-text";
    } else if ((areas = "Carmel Valley Village/Los Tulares/Sleepy Hollow")) {
      coverPageFontSize = "small-cover-page-area-text";
    } else if (maxCount >= 17) {
      coverPageFontSize = "medium-cover-page-area-text";
    }
    let oneLineOneArea = ["Country Club West", "Country Club East"];
    let specialMargins;
    if (mlsAreaID === "SCL-16N") {
      specialMargins = "monte-sereno-higher-cover-text";
    }

    let dontShowCover = false;
    if (listgenUserID === "100190") {
      dontShowCover = true;
    }
    if (listgenUserID === "105790") {
      return (
        <div className="sideways-cover-text">
          <div>{areaSplit[0]}</div>
        </div>
      );
    } else if (dontShowCover) {
      return "";
    } else if (coverPageTitle && coverPageTitle.includes("*/*")) {
      let newSplit = coverPageTitle.split("*/*");
      return (
        <div
          className={`one-cover-page-area ${
            mlsAreaID === "xSBA-20I" ? "larger-one-cover-area" : ""
          } ${coverPageFontSize} ${specialMargins}`}
        >
          <div>
            {newSplit[0]} / {newSplit[1]}
          </div>
        </div>
      );
    } else if (mlsAreaID === "SCL-16L") {
      return (
        <div className="qv4-subdivision-container">
          <div className={`one-cover-page-area`}>Belgatos</div>
          <div
            className={`one-cover-page-area cover-page-area-text-32 area-text-capitalized`}
          >
            and Surrounding Communities
          </div>
        </div>
      );
    } else if (mlsAreaID === "SCL-16M") {
      return (
        <div className="qv4-subdivision-container">
          <div className={`subdivision-title ${coverPageFontSize}`}>
            Bellaterra
          </div>
          <div className={`subdivision-title ${coverPageFontSize} `}>
            @ North 40
          </div>
        </div>
      );
    } else if (mlsAreaID === "SCL-1SB") {
      return (
        <div className="qv4-subdivision-container">
          <div className={`subdivision-title ${coverPageFontSize}`}>
            Borello Ranch Estates
          </div>
          <div className={`subdivision-title ${coverPageFontSize} `}>&</div>
          <div className={`subdivision-title ${coverPageFontSize} `}>
            Alicante
          </div>
        </div>
      );
    } else if (mlsAreaID === "SCL-15A" || mlsAreaID === "SCL-10K") {
      return (
        <div className="qv4-subdivision-container">
          <div className={`subdivision-title ${coverPageFontSize}`}>
            Dry Creek | Pruneyard
          </div>
        </div>
      );
    } else if (listgenUserID === "101001") {
      return (
        <div className="vait-cover-title-container">
          <div className="vait-cover-title">{areaSplit[0]}</div>
          <div className="vait-market-title">Market Analysis</div>
        </div>
      );
    } else if (coverPageTitle && coverPageTitle.includes("<br />")) {
      let breakSplit = coverPageTitle.split("<br />");
      return (
        <div className="qv4-subdivision-container">
          <div className={`subdivision-title ${coverPageFontSize}`}>
            {breakSplit[0]}
          </div>
          <div className={`subdivision-title ${coverPageFontSize} `}>
            {breakSplit[1]}
          </div>
          {breakSplit[2] && (
            <div className={`subdivision-title ${coverPageFontSize} `}>
              {breakSplit[2]}
            </div>
          )}
        </div>
      );
    } else if (coverPageTitle && coverPageTitle.includes("<divider>")) {
      let breakSplit = coverPageTitle.split("<divider>");
      return (
        <div className="qv4-subdivision-container">
          <div className={`subdivision-title ${coverPageFontSize}`}>
            {breakSplit[0]}
          </div>
          <div className="cover-photo-line-separator"></div>
          <div className={`subdivision-title ${coverPageFontSize}`}>
            {breakSplit[1]}
          </div>
        </div>
      );
    } else if (mlsAreaID === "SCL-16A") {
      return (
        <div className="qv4-subdivision-container">
          <div className={`subdivision-title ${coverPageFontSize}`}>
            Almond Grove
          </div>
          <div className="cover-photo-line-separator"></div>
          <div className={`subdivision-title ${coverPageFontSize}`}>
            Glen Ridge
          </div>
        </div>
      );
    } else if (mlsAreaID === "SMA-390") {
      return (
        <div className="qv4-subdivision-container">
          <div className={`subdivision-title-single-line ${coverPageFontSize}`}>
            {areaSplit[0]}
          </div>
          <div className="cover-photo-line-separator"></div>
          <div className={`subdivision-title-single-line ${coverPageFontSize}`}>
            {areaSplit[1]}
          </div>
        </div>
      );
    } else if (coverPageHeader === "Upper East and Lower Mission Canyon") {
      return (
        <div className="qv4-subdivision-container">
          <div className={`subdivision-title ${coverPageFontSize}`}>
            Lower Mission Canyon
          </div>
          <div className="cover-photo-line-separator"></div>
          <div className={`subdivision-title ${coverPageFontSize}`}>
            Upper East
          </div>
        </div>
      );
    } else if (oneLineOneArea.includes(areaSplit[0])) {
      return (
        <div className="qv4-subdivision-container">
          <div className={`subdivision-title ${coverPageFontSize}`}>
            {areaSplit[0]}
          </div>
        </div>
      );
    } else if (len === 3) {
      return (
        <div className="qv4-subdivision-container">
          <div className={`subdivision-title ${coverPageFontSize}`}>
            {areaSplit[0]}
          </div>
          <div className="cover-photo-line-separator"></div>
          <div className={`subdivision-title ${coverPageFontSize}`}>
            {areaSplit[1]}
          </div>
          <div className="cover-photo-line-separator"></div>
          <div className={`subdivision-title ${coverPageFontSize}`}>
            {areaSplit[2]}
          </div>
        </div>
      );
    } else if (len === 1) {
      return (
        <div
          className={`one-cover-page-area ${
            mlsAreaID === "xSBA-20I" ? "larger-one-cover-area" : ""
          } ${coverPageFontSize} ${specialMargins}`}
        >
          <div>{areaSplit[0]}</div>
        </div>
      );
    } else if (len === 2) {
      return (
        <div className={`qv4-subdivision-container ${specialMargins}`}>
          <div className={`subdivision-title ${coverPageFontSize}`}>
            {areaSplit[0]}
          </div>
          <div className="cover-photo-line-separator"></div>
          <div className={`subdivision-title ${coverPageFontSize}`}>
            {areaSplit[1]}
          </div>
        </div>
      );
    } else {
      return (
        <div className="three-cover-page-area">
          <div>{areaSplit[0]}</div>
          <div>{areaSplit[1]}</div>
          <div>{areaSplit[2]}</div>
        </div>
      );
    }
  };
  determineComparison = () => {
    const { infinityCheck } = propertyUtil;
    const {
      quarterlyAreaTable,
      marketHighLightsData,
      currentArea,
      only6Months,
      aprilData,
      yearEnd,
      agentData,
    } = this.props;
    const { listgenUserID } = agentData;
    const { mlsAreaID } = currentArea;
    const { marketTrends } = quarterlyAreaTable;
    let dataObj = {
      compareDays: null,
      salePriceDiff: null,
      salePriceDataPoint: null,
      receivedPerc: null,
      receivedDataPoint: null,
      daysToSellPerc: null,
      daysToSellDataPoint: null,
      unitsSoldPerc: null,
      unitsSoldDataPoint: null,
      monthComparison: null,
      highestSalePrice: null,
      useMedianSalePrice: false,
    };

    const {
      current180DaysAvgSalePrice,
      current180DaysAvgDOM,
      current180DaysUnitsSold,
      current90DaysAvgSalePrice,
      current90DaysAvgDOM,
      current90DaysUnitsSold,
      current270DaysAvgDOM,
      current270DaysAvgSalePrice,
      current270DaysPricePerSqFt,
      current270DaysUnitsSold,
      avgSalePrice90DaysPercentage,
      avgDaysOnMarket90DaysPercentage,
      soldCount90DaysPercentage,
      avgSalePrice180DaysPercentage,
      avgDaysOnMarket180DaysPercentage,
      soldCount180DaysPercentage,
      avgSalePrice270DaysPercentage,
      avgDaysOnMarket270DaysPercentage,
      soldCount270DaysPercentage,
      previous90DaysUnitsSold,
      current90DaysMedianSalePrice,
      previous90DaysMedianSalePrice,
      current180DaysMedianSalePrice,
      previous180DaysMedianSalePrice,
      current270DaysMedianSalePrice,
      previous270DaysMedianSalePrice,
      last12MonthsMedianSalePrice,
      previous12MonthsMedianSalePrice,
      prevToPrevious90DaysMedianSalePrice,
      median90DaysPercentage,
      median12MonthsPercentage,
      median180DaysPercentage,
      median270DaysPercentage,
    } = marketHighLightsData || {};

    let useMedian = false;
    if (listgenUserID === "105810" || listgenUserID === "102548") {
      useMedian = true;
      dataObj.useMedianSalePrice = true;
    }

    if (
      1 + 1 == 33 ||
      listgenUserID == "102743" ||
      listgenUserID === "105810" ||
      listgenUserID === "106028"
      // current90DaysAvgSalePrice &&
      // Number(+current90DaysAvgSalePrice) > 0 &&
      // mlsAreaID !== "SBA-20K" &&
      // mlsAreaID !== "SBA-20I" &&
      // current90DaysUnitsSold > 1
    ) {
      dataObj.compareDays = "90 Days";
      dataObj.monthComparison = 3;
      dataObj.salePriceDataPoint = useMedian
        ? current90DaysMedianSalePrice
        : current90DaysAvgSalePrice;
      dataObj.salePriceDiff = useMedian
        ? median90DaysPercentage
        : avgSalePrice90DaysPercentage;
      dataObj.daysToSellDataPoint = current90DaysAvgDOM;
      dataObj.daysToSellPerc = avgDaysOnMarket90DaysPercentage;
      dataObj.unitsSoldDataPoint = current90DaysUnitsSold;
      dataObj.unitsSoldPerc = soldCount90DaysPercentage;
      return dataObj;
    } else if (
      current180DaysAvgSalePrice &&
      +current180DaysAvgSalePrice > 0 &&
      listgenUserID !== "102548"
    ) {
      dataObj.compareDays = "6 Months";
      dataObj.monthComparison = 6;
      dataObj.salePriceDataPoint = useMedian
        ? current180DaysMedianSalePrice
        : current180DaysAvgSalePrice;
      dataObj.salePriceDiff = useMedian
        ? median180DaysPercentage
        : avgSalePrice180DaysPercentage;
      dataObj.daysToSellDataPoint = current180DaysAvgDOM;
      dataObj.daysToSellPerc = avgDaysOnMarket180DaysPercentage;
      dataObj.unitsSoldDataPoint = current180DaysUnitsSold;
      dataObj.unitsSoldPerc = soldCount180DaysPercentage;
      return dataObj;
    } else if (
      current270DaysUnitsSold &&
      +current270DaysUnitsSold > 0 &&
      listgenUserID !== "102548"
    ) {
      dataObj.compareDays = "270 Days";
      dataObj.monthComparison = 9;
      dataObj.salePriceDataPoint = useMedian
        ? current270DaysMedianSalePrice
        : current270DaysAvgSalePrice;
      dataObj.salePriceDiff = useMedian
        ? median270DaysPercentage
        : avgSalePrice270DaysPercentage;
      dataObj.daysToSellDataPoint = current270DaysAvgDOM;
      dataObj.daysToSellPerc = avgDaysOnMarket270DaysPercentage;
      dataObj.unitsSoldDataPoint = current270DaysUnitsSold;
      dataObj.unitsSoldPerc = soldCount270DaysPercentage;
      return dataObj;
    } else if (
      quarterlyAreaTable.marketTrends.filter(
        (obj) => obj.TrendName === "1-Year Trending"
      )[0].AvgSalePrice > 0
    ) {
      const {
        marketTrends: marketTrendsArea,
        marketTrendsPercentage: marketTrendsPercArea,
        graphAverages: graphAveragesArea,
      } = quarterlyAreaTable;

      let currentYearObjArea = marketTrendsArea.filter(
        (obj) => obj.TrendName === "1-Year Trending"
      )[0];
      // let currentYearObjArea = marketTrendsArea.filter(
      //   (obj) => obj.TrendName === "1 Year Trends Percentage"
      // )[0];

      let currentYearAreaPerc = marketTrendsPercArea.filter(
        (obj) => obj.TrendPercentageName === "1 Year Trends Percentage"
      )[0];

      const pastYearObjArea = marketTrendsArea.filter(
        (obj) => obj.TrendName === "Past 1-Year Trending"
      )[0];

      dataObj.compareDays = "12 Months";
      dataObj.monthComparison = 12;
      dataObj.salePriceDataPoint = useMedian
        ? last12MonthsMedianSalePrice
        : currentYearObjArea.AvgSalePrice;
      dataObj.salePriceDiff = useMedian
        ? median12MonthsPercentage
        : currentYearAreaPerc.PercentageAvgSalePrice;
      dataObj.daysToSellDataPoint = currentYearObjArea.AvgDaysOnMarket;

      dataObj.daysToSellPerc = currentYearAreaPerc.PercentageAvgDaysOnMarket;

      dataObj.unitsSoldDataPoint = currentYearObjArea.HomesSold;
      dataObj.unitsSoldPerc = currentYearAreaPerc.PercentageNumberOfHomesSold;

      return dataObj;
    } else {
      const {
        marketTrends: marketTrendsArea,
        marketTrendsPercentage: marketTrendsPercArea,
        graphAverages: graphAveragesArea,
      } = quarterlyAreaTable;

      let currentYearObjArea = marketTrendsArea.filter(
        (obj) => obj.TrendName === "3-Year Trending"
      )[0];

      let currentYearAreaPerc = marketTrendsPercArea.filter(
        (obj) => obj.TrendPercentageName === "3 Years Trends Percentage"
      )[0];
      const pastYearObjArea = marketTrendsArea.filter(
        (obj) => obj.TrendName === "Past 3-Year Trending"
      )[0];

      dataObj.compareDays = "3 Years";
      dataObj.monthComparison = 36;
      dataObj.salePriceDataPoint = currentYearObjArea.AvgSalePrice;
      dataObj.salePriceDiff = currentYearAreaPerc.PercentageAvgSalePrice;
      dataObj.daysToSellDataPoint = currentYearObjArea.AvgDaysOnMarket;

      dataObj.daysToSellPerc = currentYearAreaPerc.PercentageAvgDaysOnMarket;

      dataObj.unitsSoldDataPoint = currentYearObjArea.HomesSold;
      dataObj.unitsSoldPerc = currentYearAreaPerc.PercentageNumberOfHomesSold;

      return dataObj;
    }
  };

  render() {
    const {
      agentData,
      coverPageDetails,
      currentArea,

      photoNum,
      version,
      printVersion,
      showDataVerification,
      printDate,
      quadrantModalOpen,
      isDemo,
      hoData,
    } = this.props;
    const { listgenUserID, teamName, isMemberOfTeam, teamMembers } = agentData;
    let {
      coverPhotoCredit1,
      coverPhotoCredit2,
      agentComment,
    } = coverPageDetails;

    let {
      mlsNeighborhood,
      mlsAreaID,
      mlsCityName,
      mlsCityID,
      isCoverPhotoDataVisible,
    } = currentArea;
    if (listgenUserID === "100190") {
      isCoverPhotoDataVisible = false;
    }

    const { coverPageHeader } = hoData;
    const { formatDaySuffix } = propertyUtil;
    let isZech = listgenUserID === "100190";

    let isNegarFatemi = listgenUserID === "102734";
    // console.log("agentData", agentData);
    // console.log("mlsAreID", mlsAreaID);
    // console.log("mlsNeighborhood", mlsNeighborhood);
    // console.log("mlsCityName", mlsCityName);
    // console.log("mlsCityID", mlsCityID);
    // console.log("coverPageDetails.coverPhoto", coverPageDetails);
    const determineComparison = this.determineComparison();
    let date = new Date();
    const today = `${date.getDate()}`;
    const month = date.toLocaleString("default", { month: "long" });
    const thisYear = date.getFullYear();
    let photoName;
    // console.log("photo name is", photoName);
    // console.log("coverPageDetails", coverPageDetails);
    let cityPhoto =
      listgenUserID === "103" && version === "table-quadrants"
        ? "https://pma-coverphotos.s3.amazonaws.com/cover-oct-1.jpg"
        : listgenUserID === "103" && version === "speedometer"
        ? "https://pma-coverphotos.s3.amazonaws.com/cover-oct-2.jpg"
        : listgenUserID === "103" && version === "bubble-compare"
        ? "https://pma-coverphotos.s3.amazonaws.com/cover-oct-3.jpg"
        : photoName && photoName.length > 1
        ? photoName
        : coverPageDetails && coverPageDetails.coverPhoto
        ? coverPageDetails.coverPhoto
        : "https://pma-coverphotos.s3.amazonaws.com/solid-black.png";

    let creditStyle;
    if (listgenUserID === "106031") {
      creditStyle = "bolded-cover-credit";
    } else if (coverPhotoCredit1 && coverPhotoCredit1.length > 112) {
      creditStyle = "smaller-size-credit";
    } else if (coverPhotoCredit1 && coverPhotoCredit1.length > 95) {
      creditStyle = "medium-size-credit";
    } else if (listgenUserID === "104480") {
      creditStyle = "larger-credit-size-1";
    } else if (listgenUserID === "102340") {
      creditStyle = "right-aligned-credit";
    } else if (
      mlsAreaID === "SCL-19N" ||
      mlsAreaID === "SCL-19O" ||
      listgenUserID === "102450"
    ) {
      creditStyle = "italicized-credit";
    } else if (listgenUserID === "101566") {
      creditStyle = "gummow-credit-style";
    } else if (listgenUserID === "101567") {
      creditStyle = "kaufman-credit";
    } else if (listgenUserID === "100988") {
      creditStyle = "dianne-johnson-credit";
    }
    let coverDataStyles;
    if (listgenUserID === "106028") {
      coverDataStyles = "bolded-cover-data-text";
    }
    const isVaitCover = listgenUserID === "101001";

    let specialVait = (
      <div className="special-vait-above-cover-title">
        <div className="vait-logo-container">
          <img
            src="https://pma-coverphotos.s3.amazonaws.com/072023_AnnetteVait_LOGO-White_4Cover.png"
            alt="Annette Vait Logo"
          />
        </div>
        <div className="vait-presents-container">
          <div className="vait-presents-text">
            Annette Vait Presents your {month}, {thisYear}
          </div>
        </div>
      </div>
    );
    const isKJTeam = listgenUserID === "106028";
    let specialKJTeam = (
      <div className="special-kj-team-logo-container">
        <img src={KJTeamLogo} alt="KJ Team Logo" className="kj-team-logo-img" />
      </div>
    );

    const isScar = teamName === "Team Scarborough";
    const isUhrig = listgenUserID === "100568";
    const isLisaMcCollum = listgenUserID === "x100656";
    const isKristinArntz = listgenUserID === "100462";
    const isAnnAlbanese = listgenUserID === "100063";
    let presentedCreditStyle;
    if (isUhrig) {
      presentedCreditStyle = "uhrig";
    } else if (isScar) {
      presentedCreditStyle = "scar";
    } else if (isKristinArntz) {
      presentedCreditStyle = "arntz";
    } else if (isLisaMcCollum) {
      presentedCreditStyle = "mccollum";
    } else if (isAnnAlbanese) {
      presentedCreditStyle = "albanese";
    }
    //For team scarborough hedgerow area put credit in center
    const teamScarboroughPutInCenter =
      listgenUserID === "100588" && currentArea.mlsAreaID === "SBA-10C";

    let updatedPhoto = encodeURI(cityPhoto);
    let titleHeight = "";
    let darkerInfo;
    let coverComparisonStyles;
    if (listgenUserID === "103455") {
      darkerInfo = "darker-info-bg";
    } else if (listgenUserID === "106031") {
      coverComparisonStyles = "higher-up-cover-info";
    }
    if (listgenUserID === "105790") {
      titleHeight = "sideways-title-container";
    } else if (mlsAreaID === "SCL-10F" || mlsAreaID === "SCL-14A") {
      titleHeight = "higher-cover-text-40-top";
    }

    // if (mlsAreaID === "SBA-20L") {
    //   titleHeight = "small-title-height";
    // }

    //  console.log("determineComparison", determineComparison);
    // let hoName = renderLogicUtil.getNoteGreetingTextV4(
    //   this.props.hoData.changeDetails,
    //   "&"
    // );
    // console.log("hoName", hoName);
    // const detailsHOName = propertyUtil.changeDetailsHOName(
    //   this.props.hoData.changeDetails,
    //   true
    // );

    if (isZech) {
      return (
        <div
          className={`qv4-cover-page-container ${quadrantModalOpen && "dim"}`}
        >
          <div
            style={{
              backgroundImage: `url('${updatedPhoto}')`,
            }}
            className={`cover-page-bg-photo`}
          />
          <div className="cover-page-color-overlay" />
        </div>
      );
    } else {
      return (
        <div
          className={`qv4-cover-page-container ${quadrantModalOpen && "dim"}`}
        >
          <div
            style={{
              backgroundImage: `url('${updatedPhoto}')`,
            }}
            className={`cover-page-bg-photo xxxx`}
          />
          <div className="cover-page-color-overlay" />
          {agentData.demoCoverDate ? (
            <div className="cover-page-edition">
              {agentData.demoCoverDate}
              <span className="cover-page-small-caps">th</span>, {thisYear}
            </div>
          ) : listgenUserID === "101001" ||
            listgenUserID === "101649" ? null : printVersion ? (
            <div className="cover-page-edition">September 20, 2024</div>
          ) : (
            <div className="cover-page-edition">
              {month} {today}, {thisYear}
            </div>
          )}

          {version === "ye" && (
            <div className="special-year-end-edition">Special Edition</div>
          )}
          {isVaitCover && specialVait}
          {isKJTeam && specialKJTeam}
          <div
            className={`cover-page-title-container ${titleHeight} ${
              isVaitCover && "vait-width"
            }`}
          >
            <div className={`cover-page-city-area`}>
              {(mlsAreaID === "SCL-15A" || mlsAreaID === "SCL-10K") &&
                listgenUserID === "101566" && (
                  <div className="gummow-brothers-above-cover-logo">
                    <img
                      src="https://pma-coverphotos.s3.amazonaws.com/non-covers/010824_White_GB_TEAM_LOGO.png"
                      alt="Gummow Brothers Cover Logo"
                    />
                  </div>
                )}
              {listgenUserID === "102843" && (
                <div className="qv4-subdivision-container">
                  <div className="smaller-city-title">{mlsCityName}:</div>
                </div>
              )}
              {this.divideAreas(mlsNeighborhood, true)}

              {/*#SEPTEMBERADD BACK GARY SUBHEADER {listgenUserID === "105790" && (
                <div className="cover-photo-subtitle">
                  Some doors are meant to be opened together
                </div>
              )}*/}
              {/*{mlsAreaID === "SMA-467" && (
                <div className="cover-photo-subtitle">NEWLY LISTED</div>
              )}
              {(mlsAreaID === "138" || mlsAreaID === "78") && (
                <div className="cover-photo-subtitle">NEWLY LISTED</div>
              )}*/}
              {mlsAreaID === "SCL-1AA" && (
                <div className="cover-photo-subtitle">
                  Your Luxury Lifestyle Specialists
                </div>
              )}
              {listgenUserID === "104709" && (
                <div className="cover-photo-subtitle">
                  Where Luxury is in the Experience
                </div>
              )}
              {mlsAreaID === "SMA-350" && (
                <div className="cover-photo-subtitle small-cover-subtitle">
                  YOUR NEIGHBORHOOD IN SAN CARLOS
                </div>
              )}
              {mlsAreaID === "SMA-334D" && (
                <div className="cover-photo-subtitle small-cover-subtitle">
                  NEIGHBORHOOD
                </div>
              )}
              {agentComment && (
                <div className="cover-photo-subtitle">{agentComment}</div>
              )}
              {/*{mlsAreaID === "SBA-5F" && (
                <div className="cover-photo-subtitle">NEWLY OFFERED</div>
              )}*/}
            </div>
          </div>

          {/*{mlsAreaID === "SCL-16F" && (
            <div className="cover-photo-subtitle">
              JUST LISTED By {agentData.listgenUserFirstName}{" "}
              {agentData.listgenUserLastName}
            </div>
          )}*/}
          {/*
          {mlsAreaID === "SCL-16F" && (
            <div className="cover-photo-extra-description-info">
              <div>FIRST TIME EVER ON MARKET</div>
              <div>Quintessential Surrey Farms </div>
              <div>4 BR | 2.5 BA | 2353 SF | 17K Lot</div>
              <div>$3,495,000</div>
            </div>
          )}
*/}
          {isCoverPhotoDataVisible !== false && (
            <div
              className={`cover-page-text-container ${
                coverComparisonStyles && coverComparisonStyles
              } ${coverDataStyles}`}
            >
              <div className="cover-page-body">
                <div
                  className={`cover-page-body-sideways-text ${
                    determineComparison.compareDays === "6 Months" ||
                    (determineComparison.compareDays === "12 Months" &&
                      "small-cp-comparison")
                  } ${darkerInfo}`}
                >
                  Last {determineComparison.compareDays}{" "}
                  <span className="sideways-lowercase">vs</span> Previous{" "}
                  {determineComparison.compareDays}
                </div>

                <div className={`cover-page-body-data-container ${darkerInfo}`}>
                  <QVFourCoverPageData
                    category="Homes Sold"
                    dataPoint={determineComparison.unitsSoldDataPoint}
                    perc={determineComparison.unitsSoldPerc}
                    mlsAreaID={mlsAreaID}
                    useMedian={determineComparison.useMedianSalePrice}
                    agentData={agentData}
                  />
                  <QVFourCoverPageData
                    category="Sale Price"
                    dataPoint={determineComparison.salePriceDataPoint}
                    perc={determineComparison.salePriceDiff}
                    mlsAreaID={mlsAreaID}
                    useMedian={determineComparison.useMedianSalePrice}
                    agentData={agentData}
                  />
                  <QVFourCoverPageData
                    category="Days to Sell"
                    dataPoint={determineComparison.daysToSellDataPoint}
                    perc={determineComparison.daysToSellPerc}
                    mlsAreaID={mlsAreaID}
                    useMedian={determineComparison.useMedianSalePrice}
                    agentData={agentData}
                  />
                </div>
              </div>
            </div>
          )}
          <QVFourCoverPageInfo
            agentData={agentData}
            printVersion={printVersion}
            currentArea={currentArea}
            isDemo={isDemo}
            hoData={hoData}
          />
          {listgenUserID === "102450" && (
            <div className="special-bottom-cover-photo-text">
              <p>
                Ranked top 1.5% of 1.6 million agents in the USA by Wall Street
                Journal
              </p>
              <p>Real Trends America's Best Real Estate Professionals</p>
            </div>
          )}

          {coverPhotoCredit1 && coverPhotoCredit1.length > 1 && (
            <div
              className={`corner-cover-photo-credit ${creditStyle} ${presentedCreditStyle}`}
              style={
                teamScarboroughPutInCenter ? { width: "100%", left: 0 } : {}
              }
            >
              <p
                style={
                  teamScarboroughPutInCenter ? { textAlign: "center" } : {}
                }
              >
                {coverPhotoCredit1}
              </p>

              {coverPhotoCredit2 && coverPhotoCredit2.length > 1 && (
                <p
                  style={
                    teamScarboroughPutInCenter ? { textAlign: "center" } : {}
                  }
                >
                  {coverPhotoCredit2}
                </p>
              )}
            </div>
          )}
        </div>
      );
    }
  }
}
export default QVFourCoverPage;
