import React, { Component, Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  withStyles,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Divider,
  Input,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide,
} from "@material-ui/core";
import { Grid, Typography, CircularProgress } from "@material-ui/core";
import { compose } from "recompose";
import styles from "views/Pma/PmaEditor/CommonEditor/common.scss";
import { withSnackbar } from "notistack";
import classNames from "classnames";
import ClearIcon from "@material-ui/icons/Clear";
import { showSuccessNotification } from "common/helpers";
import { showErrorNotification } from "common/helpers";
import { AddUpdatePmaAdmin } from "services/agent";

import { propertyUtil } from "views/Pma/PropertyUtil";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PropHighlightsEditProperty = (props) => {
  const {
    //representedOptions,
    editPropFormData,
    handleInputChange,
    openUploadMedia,
    onCloseFn,
    oldPropData,
    areaID,
    onEditFormSubmit,
    fromPhotoTable,
    agentData,
    handleStatusChange,
    selectedStatus,
  } = props;
  console.log("inside edit property props", props.editPropFormData);
  const { isMemberOfTeam, agentLicenseNumber, teamMembers } = agentData;
  const teamMember1License =
    isMemberOfTeam && teamMembers[0].agentLicenseNumber;
  const teamMember2License =
    isMemberOfTeam && teamMembers[1].agentLicenseNumber;

  const { formatSuffix } = propertyUtil;

  // let isListingAgent = false;

  // if (
  //   (!isMemberOfTeam &&
  //     agentLicenseNumber === editPropFormData.listingAgentLicenseID) ||
  //   (isMemberOfTeam &&
  //     (teamMember1License === editPropFormData.listingAgentLicenseID ||
  //       teamMember2License === editPropFormData.listingAgentLicenseID)) ||
  //   (editPropFormData.isCoListingAgent &&
  //     editPropFormData.isCoListingAgent === "true") ||
  //   editPropFormData.coListingAgents
  // ) {
  //   isListingAgent = true;
  // }
  // let isSellingAgent = false;
  // if (
  //   (!isMemberOfTeam &&
  //     agentLicenseNumber === editPropFormData.sellingAgentLicenseID) ||
  //   (isMemberOfTeam &&
  //     (teamMember1License === editPropFormData.sellingAgentLicenseID ||
  //       teamMember2License === editPropFormData.sellingAgentLicenseID)) ||
  //   (editPropFormData.isCoSellingAgent &&
  //     editPropFormData.isCoSellingAgent === "true")
  // ) {
  //   isSellingAgent = true;
  // }

  let mapOrder = [];
  if (editPropFormData.isBuyingAgent && editPropFormData.isSellingAgent) {
    mapOrder = ["Buyer/Seller", "Buyer", "Seller", "N/A"];
  } else if (editPropFormData.isBuyingAgent) {
    mapOrder = ["Buyer", "Seller", "N/A", "Buyer/Seller"];
  } else if (editPropFormData.isSellingAgent) {
    mapOrder = ["Seller", "N/A", "Buyer/Seller", "Buyer"];
  } else {
    mapOrder = ["N/A", "Buyer/Seller", "Buyer", "Seller"];
  }

  let oldRepresented =
    oldPropData.isBuyingAgent && oldPropData.isSellingAgent
      ? "Buyer/Seller"
      : oldPropData.isBuyingAgent
      ? "Buyer"
      : oldPropData.isSellingAgent
      ? "Seller"
      : "N/A";

  let currentRepresented =
    editPropFormData.isBuyingAgent && editPropFormData.isSellingAgent
      ? "Buyer/Seller"
      : editPropFormData.isBuyingAgent
      ? "Buyer"
      : editPropFormData.isSellingAgent
      ? "Seller"
      : "N/A";
  console.log("oldPropData", oldPropData);
  return (
    <Fragment>
      <Dialog
        open={true}
        //onClose={onCloseFn}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xl"
        // fullScreen={true}
        fullWidth={true}
        class
        TransitionComponent={Transition}
      >
        <div
          className={`${
            fromPhotoTable
              ? "photo-table-photo-edit-property-container"
              : "photo-edit-property-container"
          }`}
        >
          <div className="photo-edit-property-title-container">
            <p className="photo-edit-property-title">Change Property Details</p>
            <div className="photo-edit-property-title-divider" />
          </div>
          <div className="photo-edit-property-form old-props">
            <div className="photo-edit-property-form-title-container">
              <p className="photo-edit-property-form-title-text">
                Current Property Details{" "}
                <span className="long-dash-title">—</span> From the MLS
              </p>
              <div className="photo-edit-property-form-title-divider"></div>
            </div>
            <div className="photo-edit-property-form-body">
              <div className="photo-edit-form-body-col">
                <div className="photo-edit-form-thead">
                  <div className="represented-th">
                    <p>Status</p>
                  </div>
                  <div className="represented-th">
                    <p>Represented</p>
                  </div>
                  <div className="address-th">Address</div>
                  {!fromPhotoTable && <div className="city-th">City</div>}
                  {oldPropData.status === "Sold" ? (
                    <div className="sale-price-th">Price</div>
                  ) : (
                    <div className="sale-price-th">List Price</div>
                  )}

                  <div className="brokerage-th">Brokerage</div>
                  <div className="photo-th">
                    <p>MLS Photo</p>
                    <p>Shown Here</p>
                  </div>
                </div>
                <div className="photo-edit-form-input">
                  <div className="status-td current-status">
                    {oldPropData.status}
                  </div>
                  <div className="represented-td centered-text">
                    <div>{oldRepresented}</div>{" "}
                  </div>
                  <div className="address-td">
                    <div>
                      {" "}
                      {oldPropData.streetNumber === "-" ||
                      oldPropData.streetNumber === "0"
                        ? null
                        : oldPropData.streetNumber}{" "}
                      {oldPropData.streetName}{" "}
                      {oldPropData.streetSuffix &&
                        oldPropData.streetSuffix !== "-" &&
                        formatSuffix(oldPropData.streetSuffix, areaID)}
                      {oldPropData.propertyType === "Townhouse" &&
                      oldPropData.unitNumber !== "-"
                        ? `, #${oldPropData.unitNumber}`
                        : ""}
                    </div>
                  </div>
                  {!fromPhotoTable && (
                    <div className="city-name-td">
                      <div>{oldPropData.cityName && oldPropData.cityName}</div>
                    </div>
                  )}

                  <div className="sale-price-td">
                    {oldPropData.status === "Sold" ? (
                      <div>
                        ${Number(oldPropData.salePrice).toLocaleString()}
                      </div>
                    ) : (
                      <div>
                        ${Number(oldPropData.listPrice).toLocaleString()}
                      </div>
                    )}
                  </div>

                  <div className="brokerage-name-td">
                    <div>{oldPropData.brokerageName}</div>
                  </div>
                  <div className="photo-td">
                    <div className="photo-add-photo-container no-hover">
                      <img src={oldPropData.photoURL} alt="Home" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="photo-edit-property-form new-props">
            <div className="photo-edit-property-form-title-container">
              <p className="photo-edit-property-form-title-text">
                Make Changes to this property here:
              </p>
              <div className="photo-edit-property-form-title-divider"></div>
            </div>
            <div className="photo-edit-property-form-body photo-body-mt">
              <div className="photo-edit-form-body-col">
                <div className="photo-edit-form-thead">
                  <div className="represented-th">
                    <p>Status</p>
                  </div>
                  <div className="represented-th">
                    <p>Represented</p>
                  </div>
                  <div className="address-th">Address</div>
                  {!fromPhotoTable && <div className="city-th">City</div>}
                  {editPropFormData.status === "Sold" ? (
                    <div className="sale-price-th">Price</div>
                  ) : (
                    <div className="sale-price-th">List Price</div>
                  )}

                  <div className="brokerage-th">Brokerage</div>
                  <div className="photo-th">
                    <p>Photo</p>
                  </div>
                </div>
                <div className="photo-edit-form-input">
                  <div className="status-td">
                    <div className="radio-container">
                      <div className="radio-and-label">
                        <input
                          type="radio"
                          id="sold"
                          value="Sold"
                          checked={editPropFormData.status === "Sold"}
                          onChange={handleStatusChange}
                        />
                        <label htmlFor="sold">Sold</label>
                      </div>
                      <div className="radio-and-label">
                        <input
                          type="radio"
                          id="active"
                          value="Active"
                          checked={editPropFormData.status === "Active"}
                          onChange={handleStatusChange}
                        />
                        <label htmlFor="active">Active</label>
                      </div>
                      <div className="radio-and-label">
                        <input
                          type="radio"
                          id="pending"
                          value="Pending"
                          checked={editPropFormData.status === "Pending"}
                          onChange={handleStatusChange}
                        />
                        <label htmlFor="pending">Pending</label>
                      </div>
                      <div className="radio-and-label">
                        <input
                          type="radio"
                          id="contingent"
                          value="Contingent"
                          checked={editPropFormData.status === "Contingent"}
                          onChange={handleStatusChange}
                        />
                        <label htmlFor="contingent">Contingent</label>
                      </div>
                    </div>
                  </div>
                  <div className="represented-td">
                    <div
                      className={`prop-highlight-arrow-down ${
                        fromPhotoTable && "higher-prop-highlight-arrow-down"
                      }`}
                      onClick={props.handleRepresentedOptionsFunc}
                    ></div>
                    <div className="prop-highlight-options">
                      {mapOrder &&
                        mapOrder.map((option) => (
                          <div key={option}>{option}</div>
                        ))}
                    </div>
                  </div>

                  <div className="address-input-col address-td">
                    <input
                      type="text"
                      id="streetNumber"
                      name="streetNumber"
                      value={editPropFormData.streetNumber || ""}
                      onChange={handleInputChange}
                      placeholder="Street Number"
                    ></input>
                    <input
                      type="text"
                      id="streetName"
                      name="streetName"
                      value={editPropFormData.streetName || ""}
                      onChange={handleInputChange}
                      placeholder="Street Name"
                    ></input>
                    <input
                      type="text"
                      id="streetSuffix"
                      name="streetSuffix"
                      value={editPropFormData.streetSuffix || ""}
                      onChange={handleInputChange}
                      placeholder="Street Suffix"
                    ></input>

                    <input
                      type="text"
                      id="unitNumber"
                      name="unitNumber"
                      value={editPropFormData.unitNumber || ""}
                      onChange={handleInputChange}
                      placeholder="Unit Number"
                    ></input>
                  </div>
                  {!fromPhotoTable && (
                    <div className="city-name-td">
                      <input
                        type="text"
                        id="cityName"
                        name="cityName"
                        value={editPropFormData.cityName || ""}
                        onChange={handleInputChange}
                      ></input>
                    </div>
                  )}

                  <div className="sale-price-input sale-price-td">
                    <span className="sale-price-input-dollar">$</span>
                    {editPropFormData.status === "Sold" ? (
                      <input
                        type="text"
                        id="sale-price"
                        name="salePrice"
                        value={editPropFormData.salePrice}
                        onChange={handleInputChange}
                      ></input>
                    ) : (
                      <input
                        type="text"
                        id="list-price"
                        name="listPrice"
                        value={editPropFormData.listPrice}
                        onChange={handleInputChange}
                      ></input>
                    )}
                  </div>

                  <div className="brokerage-name-td">
                    <input
                      type="text"
                      id="brokerage-name"
                      name="brokerageName"
                      value={editPropFormData.brokerageName || ""}
                      onChange={handleInputChange}
                    ></input>
                  </div>

                  <div className="photo-td">
                    {editPropFormData.justAdded ? (
                      <div>
                        <div className="photo-add-photo-container no-hover">
                          <img
                            src={editPropFormData.optimizedPhotoURL}
                            alt="Home"
                          />
                        </div>
                      </div>
                    ) : (
                      <div
                        className="photo-add-photo-container add-prop photo-td"
                        onClick={(event) =>
                          openUploadMedia(
                            oldPropData.displayOrder,
                            editPropFormData.photoURL
                          )
                        }
                      >
                        <p>Click to</p>
                        <p>Upload Photo</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="photo-edit-form-note">
            NOTE: If you are trying to change this property to an entirely new
            property, please Cancel out of the modal, and click the button above
            the table to add another property
          </div>
          <div className="photo-edit-form-button-container">
            <div className="photo-edit-form-cancel-button" onClick={onCloseFn}>
              <p>Cancel</p>
            </div>
            <div
              className="photo-edit-form-submit-button"
              onClick={() => onEditFormSubmit(false)}
            >
              <p>Submit For All Areas</p>
            </div>
            <div
              className="photo-edit-form-submit-button"
              onClick={() => onEditFormSubmit(true)}
            >
              <p>Submit For This Area Only</p>
            </div>
          </div>
        </div>
      </Dialog>
    </Fragment>
  );
};

export default compose(
  withSnackbar,
  withStyles(styles)
)(PropHighlightsEditProperty);
