import React, { Component } from "react";
import { propertyUtil } from "views/Pma/PropertyUtil";
import { GetStateStorage } from "common/storage";

class QVFourGreetingsPS extends Component {
  // convertTextNote = (text) => {
  //   function convertNotetoHandleEmojis(text) {
  //     const emojiRegex = new RegExp("(?:\\p{Emoji}(?<!\\p{Nd}))", "gu");
  //     const emojis = text.match(emojiRegex);
  //     const unicodeEntities = emojis
  //       ? emojis.map(
  //           (emoji) => `&#x${emoji.codePointAt(0).toString(16).toUpperCase()};`
  //         )
  //       : [];
  //     let processedText = text;
  //
  //     if (emojis) {
  //       emojis.forEach((emoji, index) => {
  //         processedText = processedText.replace(emoji, unicodeEntities[index]);
  //       });
  //     }
  //
  //     return processedText;
  //   }
  //   const note =
  //     "Viva La Fiesta! 🌹💃🌹 All real estate is local and our local Santa Barbara area real estate market is vibrant! We're seeing a bit more inventory, which is great news since there are so many buyers still searching for homes. There's been a recent teeny decrease in interest rates. Many buyers and sellers are simply ready to move on with their life changes. That's where we come in. We help our clients make those transitions. Whether downsizing, consolidating, moving up or moving on, we're here to strategize and help make it happen.";
  //   const processedNote = convertNotetoHandleEmojis(note);

  //   return (
  //     <div
  //       className={`greetings-ps-text`}
  //       dangerouslySetInnerHTML={{ __html: processedNote }}
  //     />
  //   );
  // };

  calculateCount = (determineWidth) => {
    const { hoTextNote, currentPS, agentData, currentArea } = this.props;
    const { listgenUserID } = agentData;
    const { mlsAreaID } = currentArea;
    // if (!currentPS) {

    //   let filteredP = hoTextNote.split("<p>");
    //   return false;
    // } else {
    //
    // if (mlsAreaID.includes("SBA")) {
    //   return true;
    // }
    let insideCount = 0;
    insideCount += hoTextNote.length;
    if (currentPS && currentPS.length && currentPS.length > 1) {
      let length = currentPS ? currentPS.length : 0;

      insideCount += length += 104;
    }

    let filteredP = hoTextNote.split("/n");

    // if (filteredP.length === 3) {
    //   insideCount += 104;
    // }
    // if (listgenUserID === "100073") {
    //   return "small-note-text";
    //   // if (determineWidth) {
    //   //   return true;
    //   // }
    // } else if (insideCount < 590) {
    //   return "large-note-text";
    // } else if (insideCount >= 590) {
    //   if (determineWidth) {
    //     return true;
    //   }
    //   return "medium-note-text";
    // } else if (insideCount >= 690) {
    //   if (determineWidth) {
    //     return true;
    //   }
    //   return "small-note-text";
    // }
    return "";
  };
  render() {
    const { agentSignoffForGlobalNote } = propertyUtil;
    let {
      theHomeowners,
      hoTextNote,
      isMemberOfTeam,
      teamMembers,
      agentFirstNames,
      listgenUserFirstName,
      mailingNum,
      currentPS,
      fromMailPro,
      listgenUserID,
      version,
      currentArea,
      mailproAgentSignoff,
      agentData,
      printVersion,
      hoData,
    } = this.props;
    const { convertTextNote } = this;
    const { mlsAreaID } = currentArea;
    // let test1 = agentSignoffForGlobalNote(agentData, mailproAgentSignoff);

    if (listgenUserID === "103" && version === "qv4") {
      hoTextNote =
        "It's been another fascinating year with its fair share of ups and downs, but one thing that remains constant is the amazing people that make up our community – the special place we call home. We are so fortunate to be of service to our clients and we get SO much in return. Almost without exception, we make friends for life with those we serve. From our family to yours, may the Holidays be filled with love, friends, wonderful food and happy times! ";
    } else if (listgenUserID === "105" && version === "table-quadrants") {
      hoTextNote =
        "Happy 2023! Behold the magic that a new year brings! I'd like to take this opportunity to say THANK YOU! to my clients, neighbors, and friends for helping me make 2022 a rewarding year – not just in the number of homes I listed and sold but also for all the trust and confidence you’ve given me. I'm always grateful for the referrals so many of you have sent my way, and I promise to continue to deliver what I'm best known for – unmatched service.";
      currentPS = "";
    } else if (listgenUserID === "103" && version === "bubble-compare") {
      hoTextNote =
        "California has turned green practically overnight and it feels wonderful to have a fresh and vibrant atmosphere back. This year we’re expecting more stability in the market. The number of available homes for sale will continue to remain LOW which will help keep home prices UP.  Low inventory throughout the year will continue to give sellers an edge. ";
      currentPS =
        "With Spring just around the corner, buyer activity is starting to ramp up. Now is a great time to tap into my complimentary home price valuation. We make it easy…just give me call.";
    } else if (listgenUserID === "103" && version === "qv4") {
      hoTextNote =
        "Wondering what’s going to happen with home prices? According to top market experts, home prices will moderate from the peak we saw during the pandemic, but the consensus is that prices won’t fall nationally – this is especially true for our coastal communities. Over the last 18 months, you’ve likely gained a substantial amount of equity in your property.  To help you understand how to leverage your equity in today’s market, put my team to work.";
    } else if (listgenUserID === "103" && version === "table-quadrants") {
      hoTextNote =
        "The market has shifted. Current mortgage interest rates are hovering near 7%. Homes are taking longer to sell and inventory is rising, but the good news is that due to higher demand, our neighborhoods near the coastline are better insulated from market shifts than other markets inland. Pricing your home to its true market value is now more important. Allow me to provide you a no cost precision valuation to maximize your property’s highest resale value.";
    }

    let needsSmallerLineHeight = this.calculateCount();

    let { agentSignoff } = this.props;
    const isUhrig = listgenUserID === "100568";
    const isScar = listgenUserID === "100588";

    if (isMemberOfTeam && teamMembers.length >= 2) {
      if (listgenUserID === "104626") {
        agentSignoff = "Kristen and Mary Ann";
      } else if (listgenUserID === "102712") {
        agentSignoff = "Kazemi Team";
      } else if (listgenUserID === "103486") {
        agentSignoff = "Kirk and Stan";
      } else if (listgenUserID === "100329") {
        agentSignoff = "Kit & Sue";
      } else if (isScar) {
        agentSignoff = "Team Scarborough";
      } else if (listgenUserID === "106463") {
        agentSignoff = "Deb & Beth";
      } else {
        agentSignoff = agentSignoffForGlobalNote(agentData, null, false);
        // agentSignoff = agentFirstNames(listgenUserFirstName, teamMembers);
      }
    } else if (isUhrig) {
      agentSignoff = "Bryan";
    } else if (listgenUserID === "100329") {
      agentSignoff = "Kit & Sue";
    } else if (listgenUserID === "104835") {
      agentSignoff = "Patty & The Patty Dwyer Group";
    } else if (mailproAgentSignoff) {
      agentSignoff = mailproAgentSignoff;
    } else {
      agentSignoff = agentSignoffForGlobalNote(agentData, null, false);
      // agentSignoff = listgenUserFirstName;
    }

    let smallerHOText = false;
    let xsHOText = false;
    let hoTextFont = "";

    if (theHomeowners.length > 56) {
      hoTextFont = "xs-greetings-ho-text";
    } else if (theHomeowners.length > 27) {
      hoTextFont = "smaller-greetings-ho-text";
    } else {
      hoTextFont = "regular-greetings-ho-text";
    }
    let agentClass;
    let isGalliTeam = listgenUserID === "102843";
    if (isGalliTeam) {
      agentClass = "agent-signoff line-above-signoff";
    } else if (listgenUserID === "104835") {
      agentClass = "agent-signoff no-mt-agent-signoff";
    } else if (currentPS) {
      agentClass = "agent-signoff";
    } else {
      agentClass = "agent-signoff";
    }

    const {
      removeTrust,
      getSalutation,
      refactorNotePSWithRemovalBeforeSubmit,
    } = propertyUtil;

    let hasEnterSpace = hoTextNote.includes("<p><br></p>");
    let largerPSText = false;
    // if (listgenUserID === "100040" || listgenUserID === "103455") {
    //   largerPSText = true;
    // }
    let isCindyAccount = false;
    // if (listgenUserID === "102378") {
    //   currentPS =
    //     "Thinking of buying or selling, please contact me regarding the NAR changes effective August 17th";
    //   largerPSText = true;
    //   needsSmallerLineHeight = true;
    //   isCindyAccount = true;
    // }

    if (hoTextNote.length > 1) {
      return (
        <div
          className={
            fromMailPro
              ? `mailpro-ps-${mailingNum}-greetings-ps`
              : mailingNum
              ? `${mailingNum}-greetings-ps`
              : "greetings-ps"
          }
        >
          <div className="greetings-ps-title-container">
            <div className={`greetings-ps-title ${hoTextFont}`}>
              Hello {theHomeowners},
            </div>
            <div className="greetings-ps-divider" />
          </div>
          <div
            className={`${
              this.calculateCount(true) === true && "larger-greetings-ps-box"
            } greetings-ps-box ${largerPSText && "larger-ps-text"}`}
          >
            <div
              className={`greetings-ps-text ${
                needsSmallerLineHeight && needsSmallerLineHeight
              }`}
              dangerouslySetInnerHTML={{
                __html: refactorNotePSWithRemovalBeforeSubmit(hoTextNote),
              }}
              style={
                isCindyAccount ? { fontSize: "15px", margin: "0 24px" } : {}
              }
            ></div>

            {isGalliTeam ? (
              <div
                className={`${agentClass}`}
                style={isCindyAccount ? { fontSize: "15px" } : {}}
              >
                <p
                  className={`signoff ${
                    needsSmallerLineHeight && needsSmallerLineHeight
                  }`}
                >
                  {getSalutation(version, listgenUserID)},
                </p>

                {fromMailPro == true ? (
                  <p
                    className={`agent-name ${
                      needsSmallerLineHeight && needsSmallerLineHeight
                    }`}
                  >
                    {agentSignoff}
                  </p>
                ) : (
                  <p
                    className={`agent-name ${
                      needsSmallerLineHeight && needsSmallerLineHeight
                    }`}
                  >
                    {agentSignoff}
                  </p>
                )}
              </div>
            ) : (
              <div
                className={agentClass}
                style={isCindyAccount ? { fontSize: "15px" } : {}}
              >
                <p
                  className={`signoff ${
                    needsSmallerLineHeight && needsSmallerLineHeight
                  }`}
                >
                  {getSalutation(version, listgenUserID)},
                </p>
                {fromMailPro == true ? (
                  <p
                    className={`agent-name ${
                      needsSmallerLineHeight && needsSmallerLineHeight
                    }`}
                  >
                    {agentSignoff}
                  </p>
                ) : (
                  <p
                    className={`agent-name ${
                      needsSmallerLineHeight && needsSmallerLineHeight
                    }`}
                  >
                    {agentSignoff}
                  </p>
                )}
              </div>
            )}
            {/*{listgenUserID === "100462" && (
              <div
                className={`qv4-ps-line ${
                  needsSmallerLineHeight && needsSmallerLineHeight
                }`}
              >
                PS: <strong>WE HAVE A BUYER</strong> looking for the following
                on the Riviera: • <strong>Budget:</strong> up to $3,000,000 •{" "}
                <strong>Views:</strong> while views are preferred they are not
                required. • <strong>Customization Potential:</strong> our client
                is eager for an opportunity to make the home uniquely their own.
                No renovation is too big.
              </div>
            )}*/}
            {currentPS && currentPS.length > 10 && (
              <div
                className={`qv4-ps-line ${
                  needsSmallerLineHeight && needsSmallerLineHeight
                }`}
                style={
                  isCindyAccount ? { fontSize: "15px", fontWeight: 500 } : {}
                }
              >
                {listgenUserID === "100074" ? "" : "PS:"} {currentPS}
              </div>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div
          className={
            fromMailPro
              ? `mailpro-ps-${mailingNum}-greetings-ps`
              : mailingNum
              ? `${mailingNum}-greetings-ps`
              : "greetings-ps"
          }
        >
          <div className="greetings-ps-title-container">
            <div className={`greetings-ps-title ${hoTextFont}`}>
              Hello {theHomeowners},
            </div>
            <div className="greetings-ps-divider" />
          </div>
          <div
            className={`greetings-ps-box ${largerPSText && "larger-ps-text"}`}
          >
            {hoTextNote === "<p></p>" ? (
              <div className="greetings-ps-text">
                Please stay tuned for your next PMA!
              </div>
            ) : (
              <div
                className={`greetings-ps-text ${
                  needsSmallerLineHeight && needsSmallerLineHeight
                }`}
                dangerouslySetInnerHTML={{
                  __html: refactorNotePSWithRemovalBeforeSubmit(hoTextNote),
                }}
              ></div>
            )}

            <div className={agentClass}>
              <p
                className={`signoff ${
                  needsSmallerLineHeight && needsSmallerLineHeight
                }`}
              >
                {getSalutation(version, listgenUserID)},
              </p>
              {fromMailPro == true ? (
                <p
                  className={`agent-name ${
                    needsSmallerLineHeight && needsSmallerLineHeight
                  }`}
                >
                  {isUhrig
                    ? "Bryan"
                    : isScar
                    ? "Team Scarborough"
                    : listgenUserID === "100329"
                    ? "Kit & Sue"
                    : listgenUserID === "106463"
                    ? "Deb and Beth"
                    : agentSignoff}
                </p>
              ) : isMemberOfTeam && teamMembers.length >= 2 ? (
                <p
                  className={`agent-name ${
                    needsSmallerLineHeight && needsSmallerLineHeight
                  }`}
                >
                  {listgenUserID === "100329"
                    ? "Kit & Sue"
                    : isScar
                    ? "Team Scarborough"
                    : listgenUserID === "106463"
                    ? "Deb and Beth"
                    : agentFirstNames(listgenUserFirstName, teamMembers)}
                </p>
              ) : (
                <p
                  className={`agent-name ${
                    needsSmallerLineHeight && needsSmallerLineHeight
                  }`}
                >
                  {isUhrig
                    ? "Bryan"
                    : isScar
                    ? "Team Scarborough"
                    : listgenUserID === "106463"
                    ? "Deb and Beth"
                    : listgenUserFirstName}
                </p>
              )}
            </div>
            {currentPS &&
            currentPS.length > 10 &&
            listgenUserID === "101677" ? (
              <div
                className={`qv4-ps-line ${
                  needsSmallerLineHeight && needsSmallerLineHeight
                }`}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: refactorNotePSWithRemovalBeforeSubmit(currentPS),
                  }}
                ></div>
              </div>
            ) : currentPS && currentPS.length > 10 ? (
              <div
                className={`qv4-ps-line ${
                  needsSmallerLineHeight && needsSmallerLineHeight
                }`}
              >
                PS: {currentPS}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      );
    }
  }
}

export default QVFourGreetingsPS;
