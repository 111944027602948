import React, { Component } from "react";
import BeigeImg from "./091024_PMA_MissionStmt_imageBG_3.jpg";
import { Link } from "react-router-dom";

class MissionStatementBlock extends Component {
  componentDidMount() {
    if (this.props.printVersion) {
      require("./print.scss");
    } else {
      require("./editor.scss");
    }
  }
  render() {
    const { printVersion, agentData, missionStatement } = this.props;
    const {
      missionStatementContent,
      missionStatementHeader,
    } = missionStatement;
    let { photoURL, isMemberOfTeam, listgenUserID } = agentData;

    let hasPhotoBorder = false;
    if (photoURL.includes(".jpg")) {
      hasPhotoBorder = true;
    }

    if (listgenUserID === "100656") {
      photoURL =
        "https://pma-coverphotos.s3.amazonaws.com/non-covers/091624_LisaMcCollum_missionStatmt.jpg";
    }
    let useDefault = true;
    let pronoun = "My";
    if (isMemberOfTeam) {
      pronoun = "Our";
    }
    if (useDefault) {
      return (
        <div
          className={
            printVersion
              ? "print-mission-statement-block"
              : "editor-mission-statement-block"
          }
        >
          {!printVersion && (
            <Link
              to="/agent/missionstatements"
              className="edit-button-mission-statement"
            >
              <p>Click Here to Edit</p>
              <p>Mission Statement</p>
            </Link>
          )}

          <div className="mission-statement-block-bg-container">
            <img className="mission-statement-block-bg" src={BeigeImg} />
            <div className="mission-statement-content-container">
              <div className="mission-statement-column-container">
                <div className="mission-statement-image-container">
                  <img
                    src={photoURL}
                    className={`mission-statement-image ${
                      hasPhotoBorder && "mission-photo-border"
                    }`}
                  />
                </div>
              </div>

              <div className="mission-statement-inner-container">
                <div className="mission-statement-text-container">
                  {missionStatementHeader ? (
                    <div className="mission-statement-title">
                      {missionStatementHeader}
                    </div>
                  ) : (
                    <div className="mission-statement-title">
                      {pronoun} Commitment to You
                    </div>
                  )}

                  <div className="mission-statement-text">
                    {missionStatementContent}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div
          className={
            printVersion
              ? "print-mission-statement-block-v2"
              : "editor-mission-statement-block-v2"
          }
        >
          {!printVersion && (
            <Link
              to="/agent/missionstatements"
              className="edit-button-mission-statement"
            >
              <p>Click Here to Edit</p>
              <p>Mission Statement</p>
            </Link>
          )}
          <div className="mission-statement-block-bg-container">
            <img className="mission-statement-block-bg" src={BeigeImg} />
            <div className="mission-statement-content-container">
              <div className="mission-statement-inner-container">
                <div className="mission-statement-image-container">
                  <img
                    src={photoURL}
                    className={`mission-statement-image ${
                      hasPhotoBorder && "mission-photo-border"
                    }`}
                  />
                </div>

                <div className="mission-statement-text-container">
                  {missionStatementHeader ? (
                    <div className="mission-statement-title">
                      {missionStatementHeader}
                    </div>
                  ) : (
                    <div className="mission-statement-title">
                      {pronoun} Commitment to You
                    </div>
                  )}
                  <div className="mission-statement-text">
                    {missionStatementContent}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default MissionStatementBlock;
