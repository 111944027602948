import React, { Component } from "react";
import QVFourPhotoSection from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourSecondPage/QVFourPhotoSection";
import QVFourPresentedByFooter from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourSecondPage/QVFourPresentedByFooter";
import QVFourAgentLogoHeader from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourSecondPage/QVFourAgentLogoHeader";
import QVFourPS from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourSecondPage/QVFourPS";
import QVFourPhotos from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourSecondPage/QVFourPhotos";
import YETwoShortenedMarketTableV2 from "views/Pma/PmaEditor/YearEnd2022/YETwo/YETwoSecondPage/YETwoShortenedMarketTableV2";
import MQThreeAvailableHomes from "views/Pma/PmaEditor/MidQuarter/MQThree/MQThreeSecondPage/MQThreeAvailableHomes";
import GNQRCode from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourSecondPage/GNQRCode";
import MarketIndicators from "views/Pma/PmaEditor/YearEnd2022/YETwo/YETwoSecondPage/YETwoMarketIndicators";
import QVFourEmailAndWebsiteFooter from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourSecondPage/QVFourEmailAndWebsiteFooter";

class YETwoSecondLeftV2 extends Component {
  sortByStatus = (propList) => {
    const { mlsAreaID } = this.props.currentArea;
    const { listgenUserID } = this.props.agentData;
    let maxTableRows = 13;
    const {
      activePhotoDetailsList,
      contingentPhotoDetailsList,
      pendingPhotoDetailsList,
      preSoldPhotoDetailsList,
      recentSoldPhotoDetailsList,
      agentFilteredPhotoDetailsList,
    } = this.props.quarterlyAreaTable;

    let sliced = propList
      .slice()
      .sort((a, b) => new Date(b.saleDate) - new Date(a.saleDate));
    let isLoriB = listgenUserID === "102548";
    let sold = recentSoldPhotoDetailsList ? recentSoldPhotoDetailsList : [];
    let pend = pendingPhotoDetailsList ? pendingPhotoDetailsList : [];
    let cont = contingentPhotoDetailsList ? contingentPhotoDetailsList : [];
    let active = activePhotoDetailsList ? activePhotoDetailsList : [];
    let laterSold = preSoldPhotoDetailsList ? preSoldPhotoDetailsList : [];
    let agentsProps = agentFilteredPhotoDetailsList
      ? agentFilteredPhotoDetailsList
      : [];

    let last45DaysSum =
      (agentFilteredPhotoDetailsList ? agentFilteredPhotoDetailsList : 0) +
      (activePhotoDetailsList ? activePhotoDetailsList.length : 0) +
      (contingentPhotoDetailsList ? contingentPhotoDetailsList.length : 0) +
      (pendingPhotoDetailsList ? pendingPhotoDetailsList.length : 0) +
      (recentSoldPhotoDetailsList ? recentSoldPhotoDetailsList.length : 0);

    let remainderPreSold = maxTableRows - last45DaysSum;

    const sortByStatusThenDate = (data) => {
      let sortedSolds = data
        .filter((x) => x.status === "Sold")
        .sort((a, b) => new Date(b.saleDate) - new Date(a.saleDate));

      let newConstructed = [
        ...data.filter((x) => x.status === "Active"),
        ...data.filter(
          (x) => x.status === "Pending" || x.status === "Contingent"
        ),
        ...sortedSolds,
      ];
      let uniqueProps = newConstructed.filter(
        (obj1, i, arr) =>
          arr.findIndex(
            (obj2) =>
              obj2.streetNumber === obj1.streetNumber &&
              obj2.streetName === obj1.streetName &&
              obj2.status === obj1.status &&
              obj2.unitNumber === obj1.unitNumber
          ) === i
      );

      return uniqueProps;
    };

    const trimAgentProps = (agentRelatedProps) => {
      if (listgenUserID === "100075") {
        var startDate = new Date("2024-05-01");
        let filteredByDate = agentRelatedProps.filter((a) => {
          var date = new Date(a.saleDate);
          return date >= startDate || a.status !== "Sold";
        });

        return filteredByDate;
      } else {
        return agentRelatedProps;
      }
    };

    let agentPropsLength = agentsProps.length;
    let activePropsLength = active.length;
    let trimmedProps = trimAgentProps(agentsProps);

    let trueSort1 = [
      ...trimmedProps,
      ...active,

      ...pend,
      ...cont,
      ...sold,
      ...laterSold,
    ];

    let slicedSort = trueSort1.slice(0, maxTableRows);

    let trueSort = sortByStatusThenDate(slicedSort);

    return trueSort;
  };
  render() {
    const {
      currentArea,
      agentData,
      printVersion,
      version,
      hoData,
      mlsAreas,
      aprilData,
      demoAccountHOName,
      propertyPhotoDetailsDTO,
      oldPhotoDetails,
      photoTableData,
      quarterlyAreaTable,
      quarterlyCityTable,
      MQCityDetails,
      isDemo,
    } = this.props;
    const { listgenUserID } = agentData;
    const { mlsAreaID } = currentArea;
    const { currentSoldListings } = quarterlyAreaTable;
    let useExtendedTable = false;
    if (listgenUserID === "105886") {
      useExtendedTable = true;
    }
    let sorted =
      currentSoldListings && currentSoldListings
        ? this.sortByStatus(currentSoldListings, false, true)
        : [];

    // let useExtendedTable =
    //   currentSoldListings && currentSoldListings
    //     ? this.sortByStatus(currentSoldListings, true)
    //     : [];
    // let isBellaterra = mlsAreaID === "SCL-16M";
    // if (isBellaterra) {
    //   useExtendedTable = false;
    // }
    let isSame = photoTableData === currentSoldListings;

    let useCity = false;
    if (mlsAreaID === "SBA-40F" || mlsAreaID === "SBA-40G") {
      useCity = true;
    }
    let isGailPearl = listgenUserID === "103455";
    let middleSectionStyles;
    if (isGailPearl) {
      middleSectionStyles = "compact-middle-section";
    }
    return (
      <div
        className={`${version === "year-end-2022" ? "ye2" : "qv4"}-second-left`}
      >
        <div
          className={`mq3-second-left-middle-section ${middleSectionStyles}`}
        >
          <YETwoShortenedMarketTableV2
            quarterlyCityTable={quarterlyCityTable}
            currentArea={currentArea}
            quarterlyAreaTable={
              useCity ? quarterlyCityTable : quarterlyAreaTable
            }
            useCity="false"
            hoData={hoData}
            agentData={agentData}
            MQCityDetails={MQCityDetails}
            lastMidQuarterlyPrintDate={
              quarterlyAreaTable.lastMidQuarterlyPrintDate
            }
            //printDate="Nov 1, 2021"
            printVersion={printVersion}
            currentSoldListings={currentSoldListings}
            sorted={sorted}
            useExtendedTable={useExtendedTable}
          />

          <MarketIndicators
            quarterlyAreaTable={quarterlyAreaTable}
            currentArea={currentArea}
            useCity={useCity}
            printVersion={printVersion}
            quarterlyCityTable={quarterlyCityTable}
          />
        </div>

        <MQThreeAvailableHomes
          currentArea={currentArea}
          quarterlyAreaTable={quarterlyAreaTable}
          quarterlyCityTable={quarterlyCityTable}
          MQCityDetails={MQCityDetails}
          hoData={hoData}
          agentData={agentData}
          useCity={useCity}
          altVersion="ye2"
          printVersion={printVersion}
        />

        <QVFourEmailAndWebsiteFooter
          agentData={agentData}
          currentArea={currentArea}
          printVersion={printVersion}
        />
      </div>
    );
  }
}

export default YETwoSecondLeftV2;
